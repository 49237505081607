import { render, staticRenderFns } from "./perfilesEditarPermisos.vue?vue&type=template&id=812e4ac6&"
import script from "./perfilesEditarPermisos.vue?vue&type=script&lang=js&"
export * from "./perfilesEditarPermisos.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QBreadcrumbs from 'quasar/src/components/breadcrumbs/QBreadcrumbs.js';
import QBreadcrumbsEl from 'quasar/src/components/breadcrumbs/QBreadcrumbsEl.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QTable from 'quasar/src/components/table/QTable.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QBreadcrumbs,QBreadcrumbsEl,QBtn,QScrollArea,QCard,QInput,QIcon,QCardSection,QCheckbox,QExpansionItem,QTable});
