<template>
  <q-page>
      <!-- EMPIEZA HEADER GLOBAL -->
      <div class="w100p bg-gris-panel q-px-lg q-py-sm row">
          <div class="col-8">
              <div class="fs-17 fs-w-600">{{perfilObj.clave}} - {{perfilObj.titulo}}</div>
              <q-breadcrumbs gutter="xs" class="mt-minus-2" active-color="grey-system">
              <template v-for="(breadcrumb, i) in breadcrumbs">
                  <q-breadcrumbs-el :key="i" :label="breadcrumb.name" :to="breadcrumb.to"
                                      :class="{'text-template': (i+1)===breadcrumbs.length}"
                  />
              </template>
          </q-breadcrumbs>
          </div>
          <div class="col-4 row text-right justify-end items-center">
              <q-btn color="gris-panel" label="Salir" unelevated
                  class="q-mr-md e-border-1" text-color="grey-system" :to="{name:'perfilDetalle',params:{id:perfilId}}"/>
              <q-btn color="primary" label="Guardar" @click="editarPermisos()"/>
          </div>
      </div>
      <!-- TERMINA HEADER GLOBAL -->
      <!-- COMIENZA BODY GLOBAL  -->
      <q-scroll-area class="h100-125">
        <q-card class="w100p q-px-xl" flat>
          <div class="row justify-center">
              <div class="content-container-config">
                <div class="q-mt-md">
                  <div class="header-content q-mt-lg">Editar permisos</div>
                  <div class="row filtros-section">
                      <q-input class="w30p q-pb-xs q-mb-sm" dense outlined placeholder="Buscar palabra clave" ref="buscar"
                          v-model="filter" autofocus>
                          <template #append>
                              <q-icon name="las la-search"/>
                          </template>
                      </q-input>
                      <div class="w70p q-pl-md row items-start">
                          <q-btn @click="limpiar()" color="gris-panel" icon="las la-sync" label="Limpiar" unelevated
                                  class="q-mr-md e-border-1" text-color="grey-system"/>
                      </div>
                  </div>
                  <div class="w100p">
                    <q-card-section class="table-content">
                      <div class="text-right mb4 q-mr-sm">
                          Collapsar Todos
                            <q-checkbox dense v-model="collapsarCheck"/>
                      </div>
                      <template v-for="(permiso, index) in permisos">
                        <q-expansion-item :key="index" header-class="fs-17 fs-w-600 border-bottom" :value="collapsarExpansion" :label="permiso.seccion">
                            <q-table
                              flat dense border separator="cell"
                              :data="JSON.parse(permiso.permisos)"
                              :columns="columnas"
                              row-key="permiso_id"
                              selection="multiple"
                              color="green"
                              hide-bottom
                              :filter="filter"
                              :selected.sync="permisosSeleccionados"
                              :rows-per-page-options="[0]"
                            />
                        </q-expansion-item>
                      </template>
                    </q-card-section>
                  </div>
                </div>
              </div>
          </div>
        </q-card>
      </q-scroll-area>
      <!-- TERMINA BODY GLOBAL  -->
  </q-page>
</template>

<script>

import PerfilService from "../../Services/PerfilService";
const perfilService = new PerfilService(); 

export default {
  name: "perfilDetalle",
  data() {
    return {
        breadcrumbs:[{name:"Home", to: "/home"}, {name:"Perfiles", to: "/configuraciones/perfiles"},{name:"Editar permisos"}],
        cardTab:"permisos",
        columnas:[{name: 'permisos', label: '', align: 'left', field: 'titulo',},],
        permisos: [],
        permisosSeleccionados: [],
        filter: '',
        collapsarCheck: false,
        collapsarExpansion: true,
        perfilId: this.$route.params.id,
        perfilObj:{},
        editarPerfilObj:{},
        modalEliminar: false,
        modalEditar: false,
    }
  },
  watch: {
    collapsarCheck(value){
        this.collapsarExpansion=!this.collapsarExpansion;
    }
  },
  mounted() {
      this.obtenerDetalle();
      this.obtenerPermisos();
  },
  methods: {
      obtenerDetalle(loader=true){
          if(loader) this.loader(true);
          let filtros = {perfilId: this.perfilId}
          perfilService.obtenerDetalle(filtros)
          .then(datos=>{
              this.perfilObj = datos.info;
              this.permisosSeleccionados = datos.permisos;
          })
          .catch(error => this.alertShow(error, 'error'))
          .then(()=>{if(loader)this.loader(false);});
      },
      obtenerPermisos(loader=true){
          if(loader) this.loader(true);
          perfilService.obtenerPermisos()
          .then(datos=>{
              this.permisos = datos;
          })
          .catch(error => this.alertShow(error, 'error'))
          .then(()=>{if(loader)this.loader(false);});
      },
      editarPermisos(){
          this.loader(true);
          let data = {perfilId: this.perfilId, datos: JSON.stringify(this.permisosSeleccionados)};
          perfilService.editarPermisos(data)
          .then(respuesta=>{
              this.alertShow(respuesta.mensaje, "exito");
              this.$router.push({name: 'perfilDetalle', id: this.perfilId});
              this.loader(false);
          }).catch(error=>{
              this.alertShow(error,"error")
              this.loader(false);
          });
      },
      limpiar(){
          this.filter="";
      },
      cambiarEstado(){
          if(this.collapsarCheck=false){
              this.collapsarCheck===false;
              this.collapsarExpansion=null;
          }
          else{
              
          }
      },
  },
}
</script>